import React, { useState, useEffect } from 'react';
import { NavLink, Outlet } from "react-router-dom";
import '../scss/header.scss';
import '../scss/varibles.scss';

const HeaderNav = () => {
  const [hovered, setHovered] = useState(false); // State for hover effect
  const [isMenuOpen, setMenuOpen] = useState(false); // State to track the offcanvas menu
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const closeMenu = () => {
    setMenuOpen(true); // Close the menu when a link is clicked
  };

  return (
    <div id="val-header">
      <nav className="navbar navbar-light p-0">
        <div className="container-fluid px-0">
          <NavLink to="/" className="val-header_left">
            <img src={process.env.PUBLIC_URL + '/images/VS-LOGO.png'} alt="ValueSoft Logo" />
          </NavLink>

          {/*<button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
            <span className="navbar-toggler-icon">
              <img src={process.env.PUBLIC_URL + '/images/toggle.svg'} alt="Toggle" />
            </span>
          </button> */}

          <ul className="val-header_center">
            <li>
              <NavLink exact={true} onClick={() => { scrollToTop(); setMenuOpen(false); }} activeClassName='is-active' to="/">Home</NavLink>
            </li>
            <li>
              <NavLink exact={true} onClick={() => { scrollToTop(); setMenuOpen(false); }} activeClassName='is-active' to="/our-company">About Us</NavLink>
            </li>
            <li className="mega-dropdown">
              <NavLink
                exact={true}
                onClick={scrollToTop}
                activeClassName='is-active'
                to="/services"
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
              >
                Consulting Services
                <i className={hovered ? 'bi bi-chevron-up' : 'bi bi-chevron-down'}></i>
              </NavLink>
              <ul className="mega-dropdown__body">
                <li><NavLink exact={true} onClick={() => { scrollToTop(); setMenuOpen(false); }} activeClassName='is-active' to="/services/business-solutions">Business Strategy & Solutions</NavLink></li>
                <li><NavLink exact={true} onClick={() => { scrollToTop(); setMenuOpen(false); }} activeClassName='is-active' to="/services/technology-consulting">IT Technology Consulting</NavLink></li>
                <li><NavLink exact={true} onClick={() => { scrollToTop(); setMenuOpen(false); }} activeClassName='is-active' to="/services/hr-services">Human Resources Services</NavLink></li>
                <li><NavLink exact={true} onClick={() => { scrollToTop(); setMenuOpen(false); }} activeClassName='is-active' to="/services/procurement-supplychain">Procurement & Supply Chain</NavLink></li>
                <li><NavLink exact={true} onClick={() => { scrollToTop(); setMenuOpen(false); }} activeClassName='is-active' to="/services/corporate-training">Corporate Training</NavLink></li>
                <li><NavLink exact={true} onClick={() => { scrollToTop(); setMenuOpen(false); }} activeClassName='is-active' to="/services/staffing-augmentation">Staff Augmentation</NavLink></li>
                <li><NavLink exact={true} onClick={() => { scrollToTop(); setMenuOpen(false); }} activeClassName='is-active' to="/services/outsourcing">Outsourcing</NavLink></li>
              </ul>
            </li>

            <li className="mega-dropdown">
              <NavLink
                exact={true}
                onClick={scrollToTop}
                activeClassName='is-active'
                to="/services"
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
              >
                Digital Tech
                <i className={hovered ? 'bi bi-chevron-up' : 'bi bi-chevron-down'}></i>
              </NavLink>
              <ul className="mega-dropdown__body">
                <li><NavLink exact={true} onClick={() => { scrollToTop(); setMenuOpen(false); }} activeClassName='is-active' to="/digital-tech/business-intelligence">Business Intelligence</NavLink></li>
                <li><NavLink exact={true} onClick={() => { scrollToTop(); setMenuOpen(false); }} activeClassName='is-active' to="/digital-tech/digital-asset-managment">Digital Asset Management</NavLink></li>
                <li><NavLink exact={true} onClick={() => { scrollToTop(); setMenuOpen(false); }} activeClassName='is-active' to="/digital-tech/ar-vr">AR / VR</NavLink></li>
                <li><NavLink exact={true} onClick={() => { scrollToTop(); setMenuOpen(false); }} activeClassName='is-active' to="/digital-tech/Microsoft-365">Microsoft 365</NavLink></li>
              </ul>
            </li>

            <li className="nav-item">
              <NavLink onClick={() => { scrollToTop(); setMenuOpen(false); }} className="is-active" to="/careers " >Careers </NavLink>
            </li>

            <li>
              <NavLink exact={true} onClick={() => { scrollToTop(); setMenuOpen(false); }} activeClassName='is-active' to="/contact-us">Contact Us</NavLink>
            </li>
          </ul>

          <div className="val-header_right">
            <button
              className="navbar-toggler"
              type="button"
              onClick={() => setMenuOpen(!isMenuOpen)} // Toggle menu using React state
            >
              <span className="navbar-toggler-icon">
                <img src={`${process.env.PUBLIC_URL}/images/toggle.svg`} alt="ValueSoft Toggle" />
              </span>
            </button>
          </div>

          <div
            className={`offcanvas ${isMenuOpen ? 'show no-overlay' : ''}`}
            tabIndex="-1"
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
                <img src={`${process.env.PUBLIC_URL}/images/VS-logo.png`} className="img-fluid w-50" alt="Value Soft Logo" />
              </h5>
              <button
                type="button"
                className="btn-close text-reset"
                aria-label="Close"
                onClick={() => setMenuOpen(false)} // Close menu on button click
              >
                <img className="img-fluid w-50" src={`${process.env.PUBLIC_URL}/images/close.png`} alt="Close Icon" />
              </button>
            </div>

            <div className="offcanvas-body">
              <ul className="navbar-nav justify-content-end flex-grow-1 pe-1">
                <li className="nav-item">
                  <NavLink onClick={() => { scrollToTop(); setMenuOpen(false); }} className="nav-link-active" to="/our-company" style={{ fontSize: '1.5rem', color: '#070707' }}>About Us</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink onClick={() => { scrollToTop(); setMenuOpen(false); }} className="nav-link-active" to="/services" style={{ fontSize: '1.5rem', color: '#070707' }}>Services</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink onClick={() => { scrollToTop(); setMenuOpen(false); }} className="nav-link-active" to="/services/staffing-augmentation" style={{ fontSize: '1.5rem', color: '#070707' }}> Staff Augmentation   </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink onClick={() => { scrollToTop(); setMenuOpen(false); }} className="nav-link-active" to="/services/outsourcing" style={{ fontSize: '1.5rem', color: '#070707' }}> Outsourcing   </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink onClick={() => { scrollToTop(); setMenuOpen(false); }} className='nav-link-active' to="/digital-tech/Microsoft-365" style={{ fontSize: '1.5rem', color: '#070707' }}> Microsoft 365   </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink onClick={() => { scrollToTop(); setMenuOpen(false); }} className="nav-link-active" to="/careers " style={{ fontSize: '1.5rem', color: '#070707' }}>Careers </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink onClick={() => { scrollToTop(); setMenuOpen(false); }} className='nav-link-active' to="/contact-us" style={{ fontSize: '1.5rem', color: '#070707' }}>Contact Us   </NavLink>
                </li>
              </ul>
            </div>

            <div className="cda-content text-left">
              <ul className="social">
                <li className="active"><a href="https://www.facebook.com/valuesoftinfo/" target="_blank" rel="noopener noreferrer"><i className="bi bi-facebook"></i></a></li>
                <li><a href="https://twitter.com/ValueSoft_info" target="_blank" rel="noopener noreferrer"><i className="bi bi-twitter"></i></a></li>
                <li><a href="https://www.instagram.com/valuesoftinfo" target="_blank" rel="noopener noreferrer"><i className="bi bi-instagram"></i></a></li>
                <li><a href="https://www.linkedin.com/company/value-soft-info-services-pvt-ltd-your-global-it-partner/" target="_blank" rel="noopener noreferrer"><i className="bi bi-linkedin"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </nav>

      <Outlet />
    </div>

  );
};

export default HeaderNav;
