import React, { useEffect } from "react";

const Careers = () => {
  useEffect(() => {
    // Dynamically inject the script into the page
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://jobsapi.ceipal.com/APISource/widget.js";
    script.setAttribute("data-ceipal-api-key", "Y29OL3k0Z29rbTZpbFB4UlpoWndmdz09");
    script.setAttribute("data-ceipal-career-portal-id", "Z3RkUkt2OXZJVld2MjFpOVRSTXoxZz09");
    document.body.appendChild(script);

    // Cleanup script on component unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <h1>Careers</h1>
      <div id="example-widget-container"></div>
    </div>
  );
};

export default Careers;
