import * as React from "react";
import { Routes, Route, Outlet, Link,   useParams } from "react-router-dom";
import Home from "./components/Home";
import Company from "./components/InnerPage/Company";
import Careers from "./components//InnerPage/Careers";
import Breadcrumb from "./components/InnerPage/Breadcrumb";
import Services from "./components/InnerPage/Services";
import M365 from "./components/InnerPage/M365";
import BusinessSolutions  from "./components/InnerPage/businessSolutions";
import ItConsultingPage from "./components/InnerPage/itConsulting";
import HrSolutionsPage from "./components/InnerPage/HrSolutions";
import ProcurementPage from "./components/InnerPage/ProcurementSupplychain";
import CorporateTrainingtPage from "./components/InnerPage/CorporateTraining";
import StaffingAugmentationPage from "./components/InnerPage/staffingaugmention";
import OutsourcingPage from "./components/InnerPage/outsourcing";
import BusinessIntelligencePage from "./components/InnerPage/BusinessIntelligence";
import DAManagmentPage from "./components/InnerPage/digital-asset-management";
import ArVrPage from "./components/InnerPage/Ar-Vr";
import ContactPage from "./components/InnerPage/ContactIndex";
import { Pagenotfound } from "./components/Pagenotfound";
import { M365BD } from "./components/Data/BreadcrumbData";
import M365Page from "./components/InnerPage/M365";

const Rooting = () => {
    return (

        <>

            <div  >

                <Routes  >
                    <Route exact path='/'  element={<Home />} />
                    <Route path="/our-company"  element={<Company />} />
                    
                    <Route path="/careers" element={<Careers />} />
                    <Route path="/services" element={<Services />} />
                    <Route path="/services/business-solutions" element={<BusinessSolutions />} />
                    <Route path="/services/technology-consulting" element={<ItConsultingPage />} />
                    <Route path="/services/hr-services" element={<HrSolutionsPage />} />
                    <Route path="/services/procurement-supplychain" element={<ProcurementPage />} />
                    <Route path="/services/corporate-training" element={<CorporateTrainingtPage />} />
                    <Route path="/services/staffing-augmentation" element={<StaffingAugmentationPage />} />
                    <Route path="/services/outsourcing" element={<OutsourcingPage />} />
                    <Route path="/digital-tech/business-intelligence" element={<BusinessIntelligencePage />} />
                    <Route path="/digital-tech/digital-asset-managment" element={<DAManagmentPage />} />
                    <Route path="/digital-tech/ar-vr" element={<ArVrPage />} />
                    
                    <Route path="/digital-tech/Microsoft-365" element={<M365Page />} />
                    <Route path="/contact-us" element={<ContactPage />} />
                    <Route path="*" element={<Pagenotfound />}  />
                   

                </Routes>
            </div>

        </>

    )

}


export default Rooting;